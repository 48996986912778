import { Box, Container, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

export default function FrontendFooter(): JSX.Element {
  return (
    <Box px="4">
      <Container maxW="container.xl">
        <SimpleGrid py="8" templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr' }} spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Image src="/rtr_logo_footer.png" />
              <Text fontSize="sm" mt="2">
                © {new Date().getFullYear()} RTRFM Ltd
              </Text>
            </Box>
          </Stack>
          <Stack spacing={2}>
            <Link href={'/'}>
              <a>Home</a>
            </Link>
            <Link href={'/subscribe'}>
              <a>Subscribe</a>
            </Link>
            <Link href={'/subscribe/0'}>
              <a>Donate</a>
            </Link>
            <Link href={'/paymypledge'}>
              <a>Pay Pledge</a>
            </Link>
          </Stack>
          <Stack spacing={2}>
            <Link href={'https://rtrfm.com.au'}>
              <a>Station Website</a>
            </Link>
            <Link href={'https://rtrfm.com.au/terms/'}>
              <a>Terms of Use</a>
            </Link>
            <Link href={'https://rtrfm.com.au/privacy-policy/'}>
              <a>Privacy Policy</a>
            </Link>
          </Stack>
          <Stack direction="row" spacing={6}>
            <Link href="https://www.instagram.com/rtrfm">
              <a>
                <img src="/instagram.svg" width="24px" height="24px" alt="RTRFM on Instagram" />
              </a>
            </Link>
            <Link href="https://twitter.com/RTRFM">
              <a>
                <img src="/twitter.svg" width="24px" height="24px" alt="RTRFM on Twitter" />
              </a>
            </Link>
            <Link href="https://www.youtube.com/user/RTRFMPerth">
              <a>
                <img src="/youtube.svg" width="24px" height="24px" alt="RTRFM on Youtube" />
              </a>
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
